<template>
    <v-container>
        <AddTask/>
        <TasksTable />
    </v-container>  
</template>

<script>
import TasksTable from '@/components/templates/TasksTable'
import AddTask from '@/components/molecules/AddTask'

export default {
    components: {
		TasksTable,
		AddTask
    },
    methods: {
        checkIfIsLogged() {
            this.$axios
                .get(`${this.url}api/login/islogged`, { headers: { 'x-access-token': localStorage.getItem('user') }})
                .then((el) => {
                    let tkn = el.data.message;
                    if(tkn != 'ok'){
                        localStorage.clear();
                        this.$router.push("/");
                        this.$store.commit("removeUser");
                    }
                })
                .catch(() => {
                    // this.isLoaded = false;
                });
        },
    },
    created(){
        this.checkIfIsLogged();
    }
}
</script>