<template>
    <div>
        <v-container class="my-2 text-left">
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="350px"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-model="date"
                        readonly
                        v-on="on"
                        color="grey"
                        dark
                        class="mr-5 mb-4"
                    >
                        {{ date }}
                    </v-btn>
                </template>
                <v-date-picker
                    v-model="date"
                    scrollable
                    type="month"
                    @change="dateChange(date)"
                >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-dialog>
        </v-container>
        <div class="d-flex flex-row align-baseline">
            <v-text-field
                @input="setFilterTaskSearchAdmin"
                :value="getTaskSearchAdmin"
                label="Filtruj"
                single-line
                hide-details
                class="mb-3 mr-5"
            ></v-text-field>
            <v-switch
                v-model="getSwitchEnd"
                @change="updateFilterSwitchEnd()"
                label="Zakończone"
                class="mr-4"
            ></v-switch>
            <v-switch
                v-model="getSwitchReject"
                @change="updateFilterSwitchReject()"
                label="Anulowane"
            ></v-switch>
        </div>
        <v-data-table
            :loading="!isDownloaded"
            :search="getTaskSearchAdmin"
            :headers="headers"
            :items="filteredItems"
            class="elevation-2"
            :sort-desc="false"
            :sort-by="['date']"
            :disable-pagination="true"
            :hide-default-footer="true"
            item-key="id"
        >
            <template v-slot:item.show="{ item }">
                <router-link :to="`task/${item._id}`">
                    <v-btn 
                        style="min-width: 35px; padding: 0;"
                        color="green"
                        class="mt-1 mb-1 mr-2 white--text"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" style="max-width: 20px; rotate: 180deg;" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#fff" height="20px" width="20px" version="1.1" id="Layer_1" viewBox="0 0 476.213 476.213" xml:space="preserve">
                            <polygon points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5   57.427,253.107 476.213,253.107 "/>
                        </svg>
                    </v-btn>
                </router-link>
            </template>

            <template v-slot:item.clientId="{ item }">
                {{ item.clientId ? item.clientId : "" }}
            </template>

            <template v-slot:item.copywriterId="{ item }">
                <div :class="{ opacity: item.copywriterIsbanned }">
                    {{ item.copywriterId ? item.copywriterId : "" }}
                </div>
            </template>

            <template v-slot:item.pmId="{ item }">
                {{ item.pmId ? item.pmId : "" }}
            </template>

            <template v-slot:item.korektor="{ item }">
                <div :class="{ opacity: item.korektorIsBanned }">
                    {{ item.korektor ? item.korektor : "" }}
                </div>
            </template>

            <template v-slot:item.date="{ item }">
                <span :style="`color: ${item.copyLate ? 'red' : ''}`">{{
                    item.date ? item.date.split("T")[0] : ""
                }}</span>
            </template>

            <template v-slot:item.dateCp="{ item }">
                {{ item.dateCp ? item.dateCp.split("T")[0] : "" }}
                {{ correctDate(item.dateCp) }}
            </template>

            <template v-slot:item.copyRate="{ item }">
                {{ item.copyRate ? item.copyRate.toFixed(2) : "" }}
            </template>

            <template v-slot:item.fullRate="{ item }">
                {{ item.fullRate ? item.fullRate.toFixed(2) : "" }}
            </template>
            <template v-slot:item.corectorRate="{ item }">
                {{
                    item.corectorRate
                        ? (
                                (item.corectorRate * item.characters) /
                                1000
                            ).toFixed(2)
                        : ""
                }}
            </template>

            <template v-slot:item.status="{ item }">
                <StatusChip :status="item.status" />
            </template>

            <template v-slot:item.actions="{ item }">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="grey lighten-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="mt-1 mb-1 mr-1 black--text"
                            fab
                            small
                        >
                        ...
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                        <v-btn 
                            style="padding: 0 5px;"
                            color="green"
                            class="mt-1 mb-1 mr-1 white--text"
                            @click.stop="duplicateTask(item)"
                            v-on="on"
                        >Kopiuj</v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <!-- <v-menu v-model="menu" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-btn 
                            style="padding: 0 5px;"
                            color="green"
                            class="mt-1 mb-1 mr-2 white--text"
                            @click.stop="duplicateTask(item)"
                            v-on="on"
                        >Kopiuj</v-btn>
                    </template>
                </v-menu> -->
                <!-- <div class="d-flex align-center">
                    <v-btn 
                        style="padding: 0 5px;"
                        color="green"
                        class="mt-1 mb-1 mr-2 white--text"
                        @click.stop="duplicateTask(item)"
                    >Kopiuj</v-btn>
                </div> -->
            </template>
            
        </v-data-table>
    </div>
</template>

<script>
import io from "socket.io-client";
import StatusChip from "@/components/molecules/StatusChip.vue";

const token = localStorage.getItem('user');

var connectionOptions = {
    "force new connection": true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
};

export default {
    components: {
        StatusChip,
    },
    data() {
        return {
            tasks: [],
            isDownloaded: false,
            clients: [],
            socket: io(this.url, connectionOptions),
            text: "Zadanie zostało usunięte pomyślnie",
            timeout: 2000,
            doneDisclaimer: true,
            inprogressDisclaimer: true,
            rejectedDisclaimer: true,
            headers: [
                { text: "Podgląd", value: "show", sortable: false },
                {
                    text: "Zadanie",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                { text: "Klient", value: "clientId", sortable: true },
                {
                    text: "Deadline copywriter",
                    value: "dateCp",
                    sortable: true,
                },
                { text: "Deadline", value: "date", sortable: true },
                { text: "Copywriter", value: "copywriterId", sortable: true },
                { text: "PM", value: "pmId", sortable: true },
                { text: "Korektor", value: "korektor", sortable: true },
                {
                    text: "Stawka copywritera",
                    value: "copyRate",
                    sortable: true,
                },
                { text: "Stawka", value: "fullRate", sortable: true },
                {
                    text: "Stawka korektora",
                    value: "corectorRate",
                    sortable: true,
                },
                { text: "Znaki", value: "characters", sortable: true },
                { text: "Status", value: "status", sortable: true },
                { text: "Akcje", value: "actions", sortable: false },
            ],
            date: this.$route.params.month,
            modal: false,
        };
    },

    computed: {
        role() {
            return this.$store.getters.getUserRole;
        },
        getTaskSearchAdmin() {
            return this.$store.getters.getTaskSearchAdmin;
        },
        getSwitchAccepted: {
            set(newValue) {
                console.log(newValue);
            },
            get() {
                return this.$store.getters.getSwitchAccepted;
            },
        },
        getSwitchEnd: {
            set(newValue) {
                console.log(newValue);
            },
            get() {
                return this.$store.getters.getSwitchEnd;
            },
        },
        getSwitchReject: {
            set(newValue) {
                console.log(newValue);
            },
            get() {
                return this.$store.getters.getSwitchReject;
            },
        },
        filteredItems() {
            return this.tasks.filter((i) => {
                switch (i.status) {
                    case "Zakończone":
                        if (this.getSwitchEnd == false) {
                            return i;
                        }
                        break;
                    case "Anulowane":
                        if (this.getSwitchReject == false) {
                            return i;
                        }
                        break;
                    default:
                        return i;
                }
            });
        },
    },
    methods: {
        setFilterTaskSearchAdmin(text) {
            this.$store.commit("updateFilterTaskSearchAdmin", text);
        },
        logout(){
            // localStorage.clear();

            // this.$store.commit('removeUser');
            // window.location.href = 'https://system.contentwriter.pl';
        },
        updateFilterSwitchReject() {
            this.$store.commit(
                "updateFilterSwitchReject",
                !this.getSwitchReject
            );
        },
        updateFilterSwitchEnd() {
            this.$store.commit("updateFilterSwitchEnd", !this.getSwitchEnd);
        },
        correctDate(date) {
            const newDate = new Date(date);
            return `${newDate.getHours()}:${
                newDate.getMinutes() < 9
                    ? "0" + newDate.getMinutes()
                    : newDate.getMinutes()
            }`;
        },
        getAllTasks() {
            this.$axios
                .get(`${this.url}api/tasks/from/${this.date}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    const filtredData = resp.data.map((el) => {
                        return {
                            characters: el.characters,
                            checked: el.checked,
                            clientId: el.clientId ? el.clientId.name : "",
                            copyRate:
                                el.characters !== 0 ? el.copyRate : el.copyRate,
                            copywriterId: el.copywriterId
                                ? el.copywriterId.name
                                : "",
                            pmId: el.pmId
                                ? el.pmId.name
                                : "",
                            copywriterIsbanned: el.copywriterId
                                ? el.copywriterId.isBanned
                                : "",
                            date: el.date,
                            dateCp: el.dateCp,
                            copyLate: el.copyLate,
                            korektor: el.korektorId ? el.korektorId.name : "",
                            korektorIsBanned: el.korektorId
                                ? el.korektorId.isBanned
                                : "",
                            description: el.description,
                            corectorRate: el.corectorRate,
                            fullRate:
                                el.characters !== 0 ? el.fullRate : el.fullRate,
                            name: el.name,
                            orderType: el.orderType,
                            paid: el.paid,
                            status: el.status,
                            updateTime: el.updateTime,
                            _v: el._v,
                            _id: el._id,
                            createdAt: el.createdAt,
                            client_id: el.clientId ? el.clientId._id : "",
                            korektor_id: el.korektorId._id,
                            copywriter_id: el.copywriterId._id,
                            pm_id: el.pmId ? el.pmId._id: ""
                        };
                    });
                    this.tasks = filtredData;
                    this.isDownloaded = true;
                }).catch((error) => {
                    console.log(error);
                    this.logout();
                });
        },
        changeRoute(id) {
            this.$router.push(`task/${id}`);
        },
        duplicateTask(task) {
            const statusName = 'Szkic';
            const dateCpp = new Date(task.date);
            const formattedDate = dateCpp.toISOString().split('T')[0];

            this.$axios
                .post(`${this.url}api/tasks`, {
                    name: task.name,
                    description: task.description,
                    characters: task.characters,
                    orderType: task.orderType,
                    copyRate: task.copyRate,
                    corectorRate: task.corectorRate,
                    fullRate: task.fullRate,
                    clientId: task.client_id,
                    copywriterId: task.copywriter_id,
                    pmId: task.pm_id,
                    korektorId: task.korektor_id,
                    correctorSend: false,
                    date: formattedDate,
                    dateCp: task.dateCp,
                    status: statusName,
                    createdAt: task.createdAt,
                    createdBy: this.$store.getters.getUserName,
                }, { headers: { 'x-access-token': token } })
                .then((resp) => {
                    this.sendFile(resp.data.id);
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        editTask(task) {
            console.log(task);
        },
        sendFile(taskId) {
            if (this.file != null) {
                let formData = new FormData();

                formData.append("file", this.file);
                formData.append("id", taskId);
                formData.append("emmit", false);
                this.$axios
                    .post(this.url + "api/uploads", formData, { headers: { 'x-access-token': token } })
                    .then(() => {
                        this.file = null;
                    });
            }
        },
        dateChange(date) {
            this.$router.push(`../../${this.role}/${date}`);
            this.getAllTasks();
        },
    },
    created() {
        this.getAllTasks();

        this.socket.on("newTaskAdded", () => {
            this.getAllTasks();
        });

        this.socket.on("taskDeleted", () => {
            this.getAllTasks();
        });
        this.socket.on("editedTask", () => {
            this.getAllTasks();
        });
    },
};
</script>

<style scoped lang="scss">
.opacity {
    opacity: 0.6 !important;
}
</style>
