<template>
    <v-container class="my-2 text-left">
        <v-btn color="indigo" dark medium @click="showTask = !showTask">
            Dodaj zadanie
        </v-btn>

        <v-dialog v-model="showTask" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span>Dodaj zadanie</span>
                    <span
                        @click="showTask = !showTask"
                        class="ml-auto cursor-pointer"
                        >X</span
                    >
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            v-model="name"
                            label="Nazwa"
                            :error-messages="nameErrors"
                            clearable
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"
                        ></v-text-field>

                        <VueEditor v-model="description" class="mb-4" />

                        <v-text-field
                            v-model="characters"
                            label="Liczba znaków"
                            required
                            :error-messages="characterErrors"
                            @input="$v.characters.$touch()"
                            @blur="$v.characters.$touch()"
                            clearable
                        ></v-text-field>

                        <v-btn-toggle
                            v-model="orderType"
                            class="d-flex"
                            color="primary"
                        >
                            <v-btn>
                                za 1000 zzs
                            </v-btn>
                            <v-btn>
                                za całość
                            </v-btn>
                        </v-btn-toggle>

                        <v-text-field
                            v-model="priceCopy"
                            label="Stawka dla copywritera"
                            clearable
                            :error-messages="priceCopyErrors"
                            @input="$v.priceCopy.$touch()"
                            @blur="$v.priceCopy.$touch()"
                        ></v-text-field>

                        <v-text-field
                            v-model="priceFull"
                            label="Stawka właściwa"
                            clearable
                            :error-messages="priceErrors"
                            @input="$v.priceFull.$touch()"
                            @blur="$v.priceFull.$touch()"
                        ></v-text-field>

                        <v-text-field
                            v-model="priceCorrector"
                            label="Stawka dla korektora (za 1000zzs)"
                            clearable
                            @input="$v.priceCorrector.$touch()"
                            @blur="$v.priceCorrector.$touch()"
                        ></v-text-field>

                        <v-autocomplete
                            label="Przypisz klienta"
                            :items="clients"
                            :item-text="getDisplayName"
                            :item-value="'_id'"
                            clearable
                            v-model="client"
                            required
                        ></v-autocomplete>
                        <v-select
                            label="Przypisz copywritera"
                            :items="filtredCopy"
                            :item-text="'name'"
                            :item-value="'_id'"
                            v-model="copywriter"
                            required
                            clearable
                        ></v-select>

                        <v-select
                            label="Przypisz project managera"
                            :items="filtredMod"
                            :item-text="'name'"
                            :item-value="'_id'"
                            v-model="mod"
                            required
                            clearable
                        ></v-select>

                        <v-select
                            label="Przypisz korektora"
                            :items="filtredKorektor"
                            :item-text="'name'"
                            :item-value="'_id'"
                            v-model="korektor"
                            required
                            clearable
                        ></v-select>

                        <v-menu v-model="menu" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date"
                                    label="Deadline"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker :first-day-of-week="1" v-model="date"></v-date-picker>
                        </v-menu>
                        <v-datetime-picker
                            required
                            label="Deadline dla copywritera"
                            :datePickerProps="{
                                firstDayOfWeek: 1,
                            }"
                            :timePickerProps="{
                                format: '24hr',
                            }"
                            v-model="dateCp"
                        >
                        </v-datetime-picker>
                        <v-file-input
                            v-on:change="onAddNewFile"
                            counter
                            id="file"
                            label="Dodaj załączniki"
                            multiple
                            placeholder="Zaznacz swoje pliki"
                            prepend-icon="mdi-paperclip"
                            outlined
                            :show-size="1000"
                        >

                            <template v-slot:selection="{ index, text }">
                                <v-chip
                                    v-if="index < 2"
                                    color="accent-4"
                                    dark
                                    label
                                    small
                                >
                                    {{ text }}
                                </v-chip>

                                <span
                                    v-else-if="index === 2"
                                    class="text-overline grey--text text--darken-3 mx-2"
                                >
                                    +{{ files.length - 2 }} Pliki
                                </span>
                            </template>
                    
                        </v-file-input>
                        <!-- <v-file-input
                            id="file"
                            clearable
                            v-on:change="onAddNewFile"
                            label="Dodaj załącznik"
                        ></v-file-input> -->
                    </v-form>
                </v-card-text>
                <p class="font-weight-regular pl-6" v-if="feedback">
                    {{ feedback }}
                </p>
                <v-card-actions class="ml-4">
                    <v-btn
                        color="primary"
                        @click="addTask"
                        :disabled="$v.$invalid"
                        :loading="isLoaded"
                    >
                        Dodaj
                    </v-btn>
                    <v-alert v-if="descriptionInputCheck" text="Test" type="warning" class="mb-0 ml-14 pt-1 pb-1 pr-4">Uzupełnij opis zadania</v-alert>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import io from "socket.io-client";
import { VueEditor } from "vue2-editor";
import { validationMixin } from "vuelidate";
import { errorMsg } from "@/mixins/Validation";

const token = localStorage.getItem('user');

const { required, decimal } = require("vuelidate/lib/validators");

export default {
    mixins: [validationMixin, errorMsg],
    components: {
        VueEditor,
    },
    computed: {
        filtredCopy() {
            return this.copywriters
                .filter(
                    (copy) =>
                        (copy.role === "copywriter" ||
                            copy.email.toLowerCase() === "eter@eter.pl" ||
                            copy.email.toLowerCase() === "eter@eter.com") &&
                        !copy.isBanned
                )
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
        },
        filtredMod() {
            return this.copywriters
                .filter(
                    (mod) =>
                        mod.role === "moderator" && !mod.isBanned
                )
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
        },
        filtredKorektor() {
            return this.copywriters
                .filter(
                    (korektor) =>
                        korektor.role === "korektor" && !korektor.isBanned
                )
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
        },
    },
    name: "AddTask",
    data() {
        return {
            file: null,
            files: [],
            socket: io(this.url),
            showTask: false,
            correctorSend: false,
            name: null,
            description: null,
            descriptionInputCheck: false,
            characters: null,
            orderType: 0,
            priceCopy: null,
            priceFull: null,
            priceCorrector: null,
            client: null,
            korektor: null,
            copywriter: null,
            mod: '638e1438685d1c1759dc03da',
            menu: false,
            dateCp: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + 1,
                10,
                0
            ),
            date: new Date().toISOString().substr(0, 10),
            menuTime: false,
            feedback: null,
            copywriters: [],
            time: 2,
            clients: [],
            isLoaded: false,
        };
    },
    validations: {
        name: {
            required,
        },
        priceCopy: {
            decimal,
            required,
        },
        priceFull: {
            decimal,
            required,
        },
        characters: {
            decimal,
            required,
        },
        priceCorrector: {
            decimal,
        },
    },
    methods: {
        onAddNewFile() {
            const filesToUpload = document.querySelector("#file").files;
            this.files = filesToUpload;
        },
        getDisplayName(client) {
            return client.name || (client.firstName + ' ' + client.lastName);
        },
        resetValidation() {
            this.name = null;
            this.description = null;
            this.characters = null;
            this.orderType = 0;
            this.priceCopy = null;
            this.client = null;
            this.correctorSend = false;
            this.copywriter = null;
            this.mod = null;
            (this.priceFull = null),
                (this.dateCp = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + 1,
                    10,
                    0
                ));
            this.date = new Date().toISOString().substr(0, 10);
            this.file = null;
            this.showTask = false;
            this.getDefaultKorektor();
            this.$refs.form.resetValidation();
        },
        getName() {
            return this.$store.getters.getUserName;
        },
        addTask() {
            this.descriptionInputCheck = false
            if (this.description == null || this.description == '') {
                this.descriptionInputCheck = true
                return;
            }
            let copyRate = 0;
            let fullRate = 0;

            if (this.orderType == 0) {
                // per 1000 characters
                copyRate = (this.priceCopy * this.characters) / 1000;
                fullRate = (this.priceFull * this.characters) / 1000;
            } else {
                copyRate = this.priceCopy;
                fullRate = this.priceFull;
            }
            if (!this.$v.$invalid) {
                this.isLoaded = true;
                this.$axios
                    .post(`${this.url}api/tasks`, {
                        name: this.name,
                        description: this.description,
                        characters: this.characters,
                        orderType: this.orderType,
                        copyRate: parseFloat(copyRate),
                        corectorRate: parseFloat(this.priceCorrector),
                        fullRate: parseFloat(fullRate),
                        clientId: this.client,
                        copywriterId: this.copywriter,
                        pmId: this.mod,
                        korektorId: this.korektor,
                        correctorSend: this.correctorSend,
                        date: this.date,
                        dateCp: this.dateCp,
                        createdAt: new Date(),
                        createdBy: this.getName(),
                    }, { headers: { 'x-access-token': token } })
                    .then((resp) => {
                        this.sendFile(resp.data.id);
                        this.resetValidation();
                        this.isLoaded = false;
                    });
            }
        },
        getDefaultKorektor() {
            this.$axios
                .get(`${this.url}api/tasks/korektor/default`, { headers: { 'x-access-token': token } })
                .then((resp) => {
                    this.korektor = resp.data;
                });
        },
        sendFile(taskId) {
            console.log(this.files);
            if (this.files.length > 0) {
                let formData = new FormData();

                for( var i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];
                    formData.append('files[' + i + ']', file);
                }

                formData.append("id", taskId);
                formData.append("emmit", false);

                this.$axios
                    .post(`${this.url}api/uploads`, formData, { headers: { 'x-access-token': token }})
                    .then(() => {
                        this.$refs.form.reset();
                        this.isLoaded = false;
                        this.files = [];
                    });
            }

        },
        dynamicSort(property) {
            var sortOrder = 1;

            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }

            return function (a, b) {
                if (sortOrder == -1) {
                    return b[property].localeCompare(a[property]);
                } else {
                    return a[property].localeCompare(b[property]);
                }
            };
        },
        getClients() {
            this.$axios
                .get(`${this.url}api/clients`, {
                    headers: { 'x-access-token': token }
                })
                .then((resp) => {
                    this.clients = resp.data;
                })
                .then(() => {
                    this.clients = this.clients.sort(this.dynamicSort("name"));
                });
        },

        getCopywriters() {
            this.$axios.get(`${this.url}api/users`, {
                headers: { 'x-access-token': token }
            }).then((resp) => {
                this.copywriters = resp.data;
            });
        },
    },

    created() {
        this.getClients();
        this.getCopywriters();
        this.getDefaultKorektor();
        this.socket.on("newClientAdded", () => {
            this.getClients();
        });
    },
};
</script>

<style lang="scss">
.cursor-pointer {
    cursor: pointer;
}
.editr {
    display: none !important;
}
</style>
